<template>
  <div v-if="user" class="subscription-page">
    <div v-if="!user.subscribed && (user.agency && !user.agency.free_fee) && (user.user_plans.length && user.user_plans.filter(o => o.past_due === 1).length)" class="alert alert-danger" role="alert">
      Please subscribe to a package, or update your payment method.
    </div>
    <b-modal v-model="showPaymentModal" title="Add Payment Method">
      <AddCreditCard ref="cardForm" />
      <template v-slot:modal-footer>
        <button
            class="btn btn-primary w-100"
            :disabled="loadingSubscription"
            @click="validateCard"
        >
          <b-spinner v-if="loadingSubscription" small />
          <span v-else>ADD PAYMENT METHOD</span>
        </button>
      </template>
    </b-modal>
    <div>
      <div v-if="!loadingPlans" class="row">
        <div v-for="plan in plans" :key="plan.id" :class="{ 'col-lg-3': typeFilter === null, 'col-md-6': typeFilter !== null }">
          <div class="card card-pricing">
            <div class="card-body p-4">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0 mb-2 font-size-16">{{ plan.name }}</h5>
                  <h2 class="mt-0 mb-2"
                    >${{ plan.amount }}
                    <span class="font-size-14">/
                      <em v-if="plan.type === 'one_time'">
                      OneTime
                      </em>
                      <em v-else-if="plan.recurring.interval === 'year' && plan.recurring.interval_count === 1">
                      Yearly
                      </em>
                      <em v-else-if="plan.recurring.interval === 'month' && plan.recurring.interval_count === 1">
                      Monthly
                      </em>
                      <em v-else-if="plan.recurring.interval === 'day'">
                      Daily
                      </em>
                    </span></h2
                  >
                  <div>
                    {{plan.desc}}
                  </div>
                </div>
                <div class="align-self-center">
                  <feather type="user" />
                </div>
              </div>
              <div class="mt-5 text-center">
<!--TODO: Set not active if plan is past due-->
                <button
                  class="btn px-sm-4 btn-primary"
                  :disabled="
                    loadingSubscription !== null || (user.user_plans.length > 0 && user.user_plans.filter(o => plan.id === o.plan.stripe_price_id).length > 0)
                  "
                  @click="subscription(plan.id)"
                >
                  <b-spinner v-if="loadingSubscription === plan.id" small />
                  <span v-else>
                    <span v-if="user.user_plans.length > 0 && user.user_plans.filter(o => plan.id === o.plan.stripe_price_id).length">Active</span>
                    <span v-else>
                      <i class="uil uil-arrow-right mr-1"></i>Purchase Now for ${{
                        plan.amount
                      }}
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center mt-5">
        <b-spinner large></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import AddCreditCard from '../Common/AddCreditCard'

export default {
  components: { AddCreditCard },
  props:{
    typeFilter:{
      default:null,
      type:String
    }
  },
  data() {
    return {
      showPaymentModal:false,
      loadingPlans: false,
      loadingSubscription: null,
      showAddCard: false,
      loadingCreateCard: false,
      selectedPlan: null,
      loadingPaymentMethod: false,
      loadingSetDefault: false,
      loadingStopImpersonate: false,
      errorMsg:null
    }
  },

  computed: {
    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },

    plans() {
      let plans = this.$store.getters['subscription/allPlans'] || [];
      return plans.filter(
        (plan) => (this.typeFilter !== null ? plan.hasOwnProperty('db_plan') && plan.db_plan !== null &&  plan.db_plan.product_type === this.typeFilter : true) && (plan.visible || plan.id === this.user.stripe_plan)
      )
    },

    user() {
      return this.$store.getters['auth/user']
    },

    paymentMethods() {
      return this.$store.getters['paymentMethod/all']
    }

  },
  updated(){
    this.errorMessage();
  },
  mounted() {
    this.getPlans()
  },

  methods: {
    errorMessage(){
      this.errorMsg = sessionStorage.getItem('sub-error-msg');
    },
    logout() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(() => {})
    },

    onSuccessHandler() {
      if (this.user.agency) {
        if(this.typeFilter !== null){
          setTimeout(function(){
            // TODO: Setup events instead of relying on a timeout
            window.location.reload();
          },3000);
        }else {
          if(window.location.origin.includes('repuagency.com')){
            this.$router.push({name: 'agency.customers.index'})
          }else{
            this.$router.push({name: 'agency.dashboard'})
          }
        }
      } else {
        this.getPlans()
      }
    },

    onFailedHandler() {},

    getPlans() {
      if (this.$store.getters['subscription/allPlans']) return

      this.loadingPlans = true
      this.$store
        .dispatch('subscription/getAllPlans')
        .then(() => {
          this.loadingPlans = false
        })
        .catch(() => {
          this.loadingPlans = false
        })
    },

    subscription(plan) {
      this.selectedPlan = plan;
      if(this.user.default_payment_method){
        this.loadingSubscription = plan

        this.$store
            .dispatch('subscription/subscribe', plan)
            .then(() => {
              let that = this;
              setTimeout(function(){
                // TODO: Setup events instead of relying on a timeout
                that.loadingSubscription = null
                window.location.reload();
              },3000);
            })
            .catch(() => {
              this.loadingSubscription = null
            })
      }else{
        this.showPaymentModal = true;
      }

    },

    createPaymentMethod(paymentMethodId) {
      this.$store
        .dispatch('paymentMethod/create', paymentMethodId)
        .then(() => {
          this.$store
            .dispatch('subscription/subscribe', this.selectedPlan)
            .then(() => {
              this.loadingSubscription = null
              this.onSuccessHandler()
            })
            .catch(() => {
              this.loadingSubscription = null
              this.getPlans()
            })
        })
        .catch(() => {
          this.loadingSubscription = null
        })
    },

    setDefaultPaymentMethod(paymentMethodId) {
      this.loadingSetDefault = true
      this.$store
        .dispatch('paymentMethod/setDefault', paymentMethodId)
        .then((res) => {
          this.$store
            .dispatch('subscription/resume')
            .then(() => {
              this.loadingSetDefault = false
              this.onSuccessHandler()
            })
            .catch(() => {
              this.loadingSetDefault = false
              this.getPlans()
            })
        })
        .catch((errors) => {
          this.loadingSetDefault = false
        })
    },

    getPaymentMethods() {
      this.loadingPaymentMethod = true
      this.$store
        .dispatch('paymentMethod/getAll')
        .then((res) => {
          this.loadingPaymentMethod = false
        })
        .catch(() => {
          this.loadingPaymentMethod = false
        })
    },

    async validateCard() {
      this.loadingSubscription = true

      await this.$refs.cardForm
        .validate()
        .then((paymentMethodId) => {
          this.createPaymentMethod(paymentMethodId);
        })
        .catch(() => {
          this.loadingSubscription = null;
        })
    },

    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
        .dispatch('auth/stopImpersonate')
        .then(() => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/admin')
              this.loadingStopImpersonate = false
            })
            .catch(() => {
              this.loadingStopImpersonate = false
            })
        })
        .catch(() => {
          this.loadingStopImpersonate = false
        })
    },
  },
}
</script>

<style lang="scss">
.subscription-page {
  .payment-methods {
    .payment-method {
      padding: 10px 0;
      .card-brand {
        font-size: 16px;
        font-weight: 500;
        color: #2c2e2f;
        text-transform: uppercase;
      }
      .card-bumber,
      .card-expiration {
        font-weight: 500;
        color: #666;
      }
    }
  }
  .payment-methods .payment-method .actions {
    margin-top: 10px;
  }
  .payment-methods .payment-method .actions a[disabled] {
    opacity: 0.6;
  }
  .logout-button {
    max-width: 200px;
    position: absolute;
    top: 50px;
    right: 50px;
  }
}
</style>
